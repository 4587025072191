import { TaskTag } from 'services/todo-task-categories';
import { apiTodoTasks } from 'services/todo-tasks';
import { apiUserPatientProfileDietDiaries } from 'services/user-patient-profile-diet-diaries';
import { put, takeEvery } from 'typed-redux-saga';

function* watchCreated() {
  yield* put(
    apiTodoTasks.endpoints.createAutoTodoTaskByTagIfNoneOpen.initiate(
      TaskTag.PatientAppAddDietaryItem,
    ),
  );
}
export const sagasDietDiaries = [
  takeEvery(
    apiUserPatientProfileDietDiaries.endpoints.createUserPatientProfileDietDiary.matchFulfilled,
    watchCreated,
  ),
];
